<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="formInline.projectName" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="principal">
          <el-select v-model="formInline.principal">
            <el-option v-for="(item, index) in principalList" :key="index" :label="item.realName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手册状态" prop="manualStatus">
          <el-select v-model="formInline.manualStatus">
            <el-option label="进行中" value="1"></el-option>
            <el-option label="暂停中" value="2"></el-option>
            <el-option label="已验收" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="节点时间">
          <el-date-picker type="date" placeholder="选择开始日期" v-model="formInline.firstDraftTime"></el-date-picker>
          <span class="line">至</span>
          <el-date-picker type="date" placeholder="选择结束日期" v-model="formInline.finalDraftTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="风险状态" prop="riskstatus">
          <el-select v-model="formInline.riskstatus">
            <el-option label="无风险" value="1"></el-option>
            <el-option label="低风险" value="2"></el-option>
            <el-option label="中风险" value="3"></el-option>
            <el-option label="高风险" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="项目名称" prop="projectName" width="200"></el-table-column>
        <el-table-column label="手册分类" prop="cnName" width="200"></el-table-column>
        <el-table-column label="负责人" prop="principalName" width="200"></el-table-column>
        <el-table-column label="开发状态" prop="manualStatus" width="146">
          <template slot-scope="{row}">
            <span v-if="row.manualStatus === 1">进行中</span>
            <span v-if="row.manualStatus === 2">暂停中</span>
            <span v-if="row.manuslStatus === 3">已验收</span>
          </template>
        </el-table-column>
        <el-table-column label="节点时间" prop="deadlineTime" width="160" :formatter="dateFormat">
        </el-table-column>
        <el-table-column label="初稿时间" prop="firstDraftTime" width="160" :formatter="dateFormat"></el-table-column>
        <el-table-column label="终稿时间" prop="finalDraftTime" width="160" :formatter="dateFormat"></el-table-column>
        <el-table-column label="风险状态" prop="riskStatus" width='100'>
          <template slot-scope="{row}">
            <span v-if="row.riskStatus === 1" style="color:#009933">无风险</span>
            <span v-if="row.riskStatus === 2" style="color:#cc0000">低风险</span>
            <span v-if="row.riskStatus === 3" style="color:#cc0000">中风险</span>
            <span v-if="row.riskStatus === 4" style="color:#cc0000">高风险</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="320">
          <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="checkClick(row)">查看</el-button>
            <el-button type="primary" size="mini" @click="manualReview(row, '审核', '/audit')">手册审核</el-button>
            <el-button type="primary" size="mini" @click="auditDetails(row, '审核明细', '/auditDetail')">审核明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" @pagination="dataList"/>
      <el-dialog title="查看详情" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
        <el-form ref='dataForm' :model="temp" label-position="center">
          <el-form-item label="项目" :label-width="formLabelWidth">
            <el-input v-model="temp.projectName" readonly></el-input>
          </el-form-item>
          <el-form-item label="手册分类" :label-width="formLabelWidth">
            <el-input v-model="temp.cnName" readonly></el-input>
          </el-form-item>
          <el-form-item label="负责人" :label-width="formLabelWidth">
            <el-input v-model="temp.principalName" readonly></el-input>
          </el-form-item>
          <el-form-item label="开发状态" :label-width="formLabelWidth">
            <el-input v-model="temp.manualStatus" readonly></el-input>
          </el-form-item>
          <el-form-item label="节点时间" :label-width="formLabelWidth">
            <el-input v-model="temp.deadlineTime" readonly></el-input>
          </el-form-item>
          <el-form-item label="初稿时间" :label-width="formLabelWidth" :formatter="dateFormat">
            <el-input v-model="temp.firstDraftTime" readonly></el-input>
          </el-form-item>
          <el-form-item label="终稿时间" :label-width="formLabelWidth" :formatter="dateFormat">
            <el-input v-model="temp.finalDraftTime" readonly></el-input>
          </el-form-item>
          <el-form-item label="风险状态" :label-width="formLabelWidth">
            <el-input v-model="temp.riskStatus" readonly></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, obj } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { auditData, auditDictList, auditSearch } from  '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      formInline: {
        projectName: '',
        principal: '',
        manualStatus: '',
        firstDraftTime: '',
        finalDraftTime: '',
        riskstatus: ''
      },
      temp: {
        projectName: '',
        principal: '',
        principalName: '',
        manualStatus: '',
        deadlineTime: '',
        firstDraftTime: '',
        finalDraftTime: '',
        riskStatus: ''
      },
      riskList: [
        { name: '无风险', code: 1 },
        { name: '低风险', code: 2 },
        { name: '中风险', code: 3 },
        { name: '高风险', code: 4 }
      ],
      projectList: [
        { name: '进行中', code: 1 },
        { name: '暂停中', code: 2 },
        { name: '已验收', code: 3 }
      ],
      dialogFormVisible: false,
      formLabelWidth: '100px',
      principalList: [],
      resultList: [],
      pageSize: 10,
      currentPage: 1,
      total: 0
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pageSize
      }
      auditData(params).then(res=>{
        this.total = res.data.total
        this.resultList = res.data.data
      })
      // axios.get(cmsServerUrl + 'cms/manual/list', {
      //   params: {
      //     page: this.currentPage,
      //     limit: this.pageSize
      //   }
      // }).then(res => {
      //   this.total = res.data.total
      //   this.resultList = res.data.data
      //   console.log(this.resultList)
      // })
    },
    // 负责人
    getDictList () {
      auditDictList().then(res => {
        this.principalList = res.data.data
      })
      // axios.get(sysServerUrl + 'sys/user/list/all').then(res => {
      //   this.principalList = res.data.data
      //   console.log(this.principalList)
      // })
    },
    // 搜索
    onSubmit () {
      var myDate = new Date()
      var params = {
        page: this.currentPage,
        limit: this.pageSize,
        projectName: this.formInline.projectName,
        principal: this.formInline.principal,
        manualStatus: this.formInline.manualStatus,
        begintime: myDate.getTime(this.formInline.begintime),
        endtime: myDate.getTime(this.formInline.endtime),
        riskStatus: this.formInline.riskstatus
      }
      auditSearch(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
      // axios.get(cmsServerUrl + 'cms/manual/list', {
      //   params: {
      //       page: this.currentPage,
      //   limit: this.pageSize,
      //   projectName: this.formInline.projectName,
      //   principal: this.formInline.principal,
      //   manualStatus: this.formInline.manualStatus,
      //   begintime: myDate.getTime(this.formInline.begintime),
      //   endtime: myDate.getTime(this.formInline.endtime),
      //   riskStatus: this.formInline.riskstatus
      //   }
      // }).then(res => {
      //   this.total = res.data.total
      //   this.resultList = res.data.data
      // })
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      // this.$refs.temp.resetFields()
    },
    // 查看
    checkClick (row) {
      this.temp = Object.assign({}, row)
      this.dialogFormVisible = true
      this.temp.deadlineTime = obj.getMyDate(this.temp.deadlineTime)
      this.temp.firstDraftTime = obj.getMyDate(this.temp.firstDraftTime)
      this.temp.finalDraftTime = obj.getMyDate(this.temp.finalDraftTime)
      for (var j = 0; j < this.riskList.length; j++) {
        if (this.riskList[j].code === this.temp.riskStatus) {
          this.temp.riskStatus = this.riskList[j].name
        }
      }
      for (var k = 0; k < this.projectList.length; k++) {
        if (this.projectList[k].code === this.temp.manualStatus) {
          this.temp.manualStatus = this.projectList[k].name
        }
      }
    },
    // 手册审核
    manualReview (row, name, url) {
      let title =row.projectName+"#"+row.cnName+"#"+name
      let curl= url+'/'+row.id+'/'+row.manualVersion
      obj.tabsClick(title, curl)
      this.$router.push({ name: 'audit', params: { id: row.id, version: row.manualVersion } })
    },
    // 审核明细
    auditDetails (row, name, url) {
     let title =row.projectName+"#"+row.cnName+"#"+name
      let curl= url+'/'+row.id+'/'+row.manualVersion
      obj.tabsClick(title, curl)
      this.$router.push({ name: 'auditDetail', params: { id: row.id, version: row.manualVersion } })
    },
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.dataList()
    },
    // 时间转换
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    this.dataList()
    this.getDictList()
    // obj.areaSize()
  }
}
</script>
